import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { BeeSpinner } from 'components/BeeSpinner';
import { EnvProvider } from 'contexts/EnvProvider';
import { SegmentProvider } from 'contexts/SegmentProvider';
import { useApiaryLog } from 'hooks/useApiaryLog';
import { IEnvProps } from 'interfaces/IEnv';
import GlobalStyle from 'layouts/global';
import { FC, ReactNode, Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { routesConfiguration } from 'routes';
import GlobalStateProvider from './redux/Store';

const theme = createTheme({
  typography: {
    fontFamily: 'Work Sans, sans-serif',
  },
});

interface IAppProps extends IEnvProps {
  children: ReactNode;
}

const browserRouter = createBrowserRouter(routesConfiguration);

const App: FC<IAppProps> = (env) => {
  const { persistError } = useApiaryLog();
  const { enableDevTools } = env;
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60,
        cacheTime: 0,
        retry: false,
        refetchOnWindowFocus: false,
        onError: persistError,
      },
    },
  });
  const segmentKey = enableDevTools
    ? 'ssXzxEqjkvFgVDRF8M6oCHxebL1aYVAB'
    : 'FEFIR1EAgHZraDzKclI66vbyVD6hPVnf';

  return (
    <>
      <GlobalStyle />
      <Suspense fallback={<BeeSpinner />}>
        <SegmentProvider writeKey={segmentKey}>
          <QueryClientProvider client={queryClient}>
            {enableDevTools && <ReactQueryDevtools />}
            <EnvProvider env={env}>
              <GlobalStateProvider>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <RouterProvider router={browserRouter} />
                </ThemeProvider>
              </GlobalStateProvider>
            </EnvProvider>
          </QueryClientProvider>
        </SegmentProvider>
      </Suspense>
    </>
  );
};
export default App;
