import { IGlobalState } from 'interfaces/IGlobalState';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  setAccountCardBox,
  setAccountSelected,
  setBeesVendoCardBox,
  setCountry,
  setCustomerCardBox,
  setStoreCardBox,
  setVendorCardBox,
} from 'redux/actions';

export const useGlobalState = (): IGlobalState => {
  const dispatch = useDispatch();

  const clearState = () => {
    dispatch(setAccountCardBox(undefined));
    dispatch(setVendorCardBox(undefined));
    dispatch(setBeesVendoCardBox(undefined));
    dispatch(setStoreCardBox(undefined));
    dispatch(setCustomerCardBox(undefined));
    dispatch(setAccountSelected(undefined));
    dispatch(setCountry(undefined));
  };

  return { ...useSelector((state: IGlobalState) => state, shallowEqual), clearState };
};
