import MessageMap from '../i18n.d';

const en: MessageMap = {
  SEGMENT: {
    INVOICES_NOT_FOUND: 'Invoices not found',
    ORDERS_NOT_FOUND: 'Orders not found',
    VENDORS_NOT_FOUND: 'Vendors not found',
    STORES_NOT_FOUND: 'Stores not found',
    CUSTOMER_NOT_FOUND: 'Customer not found',
    REWARD_TRANSACTIONS_NOT_FOUND: 'Transactions not found',
    ASSOCIATED_ACCOUNTS_NOT_FOUND: 'Associated accounts not found',
    REWARD_CHALLENGES_NOT_FOUND: 'Challenges not found',
    CART_CATALOG_NOT_FOUND: 'Catalog not found',
    CART_ITEM_NOT_FOUND: 'Item not found',
    RECOMMENDATION_PRODUCTS_NOT_FOUND: 'Products not found',
  },
  APPLICATION_403_ERROR: {
    HEADER: 'Hmm... Access denied!',
    DESCRIPTION: "It looks like this page wasn't found or doesn't exist.",
    BUTTON_LABEL: 'HOME',
  },
  STORE: {
    VENDORS: {
      PAGE_TITLE: 'Store',
      LIST_TITLE: 'Vendors',
      PLURAL_RESULT: '{quantity} vendors',
      SINGULAR_RESULT: '{quantity} vendor',
      PLACEHOLDER: 'Search a vendor by Name or Vendor ID',
    },
    ITEMS: {
      PAGE_TITLE: 'Store',
      PLACEHOLDER: 'Search an item',
      DROPDOWN_PLACEHOLDER: 'Search a Vendor',
      TABLE_TITLE: 'Items',
      TABLE_ITEM_COLUMN: 'Item',
      TABLE_CATEGORIES_COLUMN: 'Categories',
      TABLE_STATUS_COLUMN: 'Status',
      TABLE_PLATFORM_ID_COLUMN: 'Item Platform ID',
      TABLE_HIDDEN_COLUMN: 'Hidden',
      TABLE_VARIANT_COLUMN: 'Variant',
      TABLE_NARCOTIC_COLUMN: 'Narcotic',
      TABLE_ALCOHOLIC_COLUMN: 'Alcoholic',
      SELECTION_MESSAGE: '{quantity} items selected /',
      DESELECTION_BUTTON: 'Deselect all',
    },
    TOOLS: {
      PAGE_TITLE: 'Store',
      ITEMS_CARD_TITLE: 'Items',
      VENDORS_CARD_TITLE: 'Vendors',
      CATEGORIES_CARD_TITLE: 'Categories',
      ITEMS_DESCRIPTION: 'List of the items available for this Store.',
      VENDORS_DESCRIPTION: 'The vendors that are available in this Store.',
      CATEGORIES_DESCRIPTION: 'All categories set for this Store.',
    },
    SELECTION: {
      PAGE_TITLE: 'Stores',
      TITLE: 'Choose a store',
      SUBTITLE: 'Choose a store to access items, categories and more.',
      PLACEHOLDER: 'Search a Store Name, ID or Vendor Name',
    },
    CATEGORIES: {
      PAGE_TITLE: 'Store',
      HEADER_TITLE: 'Categories',
      NO_CATEGORIES_FOUND: 'No categories found',
    },
    CATEGORY_DETAILS: {},
  },
  ACCOUNT: {
    ALGO_SELLING: {
      TITLE: 'Algo selling',
      PLACEHOLDER: 'Search by SKU...',
      USECASE_DROPDOWN_PLACEHOLDER: 'Search by an option',
      STORE_DROPDOWN_PLACEHOLDER: 'Select a store',
      STORE_DROPDOWN_FILTER_PLACEHOLDER: 'Search by a store',
      OUT_OF_STOCK_TABLE_TEXT:
        'The algorithm is displaying {number} products similar to the searched SKU. They are being recommended for purchase.',
      SHOW_SIMILAR_PRODUCTS: 'Show similar products',
      SHOW: 'Show',
      TABLE_HEADER_PRODUCT: 'Product',
      TABLE_HEADER_TYPE: 'Type',
      TABLE_HEADER_SITUATION: 'Situation',
      TABLE_HEADER_PLATFORM_ID: 'Item Platform ID',
      TABLE_HEADER_CATEGORIES: 'Categories',
      USECASE_QUICK_ORDER: 'Quick order',
      USECASE_CROSS_SELL: 'Cross-sell/upsell',
      USECASE_OUT_OF_STOCK: 'Out of stock replacement',
      USECASE_BEST_SELLING: 'Best-selling products',
      USECASE_FORGOTTEN_ITEMS: 'Forgotten items',
      USECASE_ALSO_PURCHASED_PDP: 'Also purchased - PDP',
      USECASE_PRODUCT_RELEVANCE: 'Product relevance',
      USECASE_REGULARS: 'Regulars',
      DEFAULT_NO_PRODUCTS_TITLE: 'No products found',
      DEFAULT_NO_PRODUCTS_DESCRIPTION:
        'There are no recommended products for this filter. Try a different one.',
      SEARCH_NO_PRODUCTS_FOUND_TITLE: 'No similar products',
      SEARCH_NO_PRODUCTS_FOUND_DESCRIPTION:
        'There are no similar products being recommended for purchase.',
      SEARCH_OUT_OF_STOCK_EMPTY_TITLE: 'Search by SKU to display similar products',
      SEARCH_OUT_OF_STOCK_EMPTY_DESCRIPTION:
        'Search results will show replacement options in case a product is out of stock.',
      SEARCH_ALSO_PURCHASED_EMPTY_TITLE: 'Search by SKU to display recommended products',
      SEARCH_ALSO_PURCHASED_EMPTY_DESCRIPTION:
        'Results will show items recommended on the details page of the searched SKU.',
    },
    TRUCK_SIMULATION: {
      ACCOUNT: 'Account',
      TITLE: 'Truck simulation',
      ADD_BUTTON: 'Add',
      UPDATE_BUTTON: 'Update',
      INPUT_PLACEHOLDER: 'Search by SKU...',
      SHOW_ALERTS: 'Show alerts ({number})',
      ALERTS_TOOLTIP: 'Details about the truck simulation.',
      EMPTY_TRUCK_TOOLTIP: 'Your truck is empty. Start adding products.',
      CATALOG_NOT_FOUND: 'Catalog not found',
      CATALOG_NOT_FOUND_MESSAGE: 'We could not find the catalog for this account.',
      ITEM_NOT_FOUND: 'Item not found',
      ITEM_NOT_FOUND_MESSAGE: 'The item you have searched for could not be found in the catalog.',
    },
    TRUCK: {
      UPDATE: 'Update',
      TRUCK: 'Truck',
      CHECKOUT: 'Checkout',
      ACCOUNT: 'Account',
      TRUCK_SIMULATION: 'Truck simulation',
      SHOW_ALERTS: 'Show alerts ({number})',
      VENDOR_SUBTOTAL: 'Subtotal: {value}',
      REMOVE_ALL: 'Remove all',
      REMOVE: 'Remove',
      CHANGED_TO_BOX: 'Due to quantity selected, your products qualify as a box.',
      BOX_MULTIPLIER_OPTION: '{option} (1 box)',
      SUMMARY_TITLE: 'Order summary',
      SUMMARY_TOTAL: 'Total',
      SUMMARY_TAX: 'Tax',
      SUMMARY_EMPTIES: 'Empties',
      SUMMARY_DELIVERY: 'Delivery fee',
      PLACEHOLDER_DELIVERY: 'Not selected',
      SUMMARY_SUBTOTAL: 'Subtotal',
      SUMMARY_DELIVERY_FEE: 'Delivery fee',
      SUMMARY_PAYMENT_FEE: 'Payment fee',
      SUMMARY_NOT_SELECTED: 'Not selected',
      ALERTS_TOOLTIP: 'Details about the truck simulation.',
      TOTAL_PRODUCTS_TOOLTIP: 'Total of {total} products in the truck.',
      NUMBER_PRODUCTS_TOOLTIP: '{number} products selected from this vendor.',
      SUMMARY_DISCLAIMER:
        'The estimated total includes all fees, sales taxes, and federal taxes. State law supersedes any pricing discrepancies.',
      REMINDER_ALERT: 'This is a simulation. Only account owners can redeem products.',
      EMPTY_TRUCK: 'Your truck is empty',
      EMPTY_TRUCK_MESSAGE: 'Do you want to go back and start the truck simulation?',
      EMPTIES: 'Empties',
      EMPTIES_BALANCE: 'Balance: {number}',
      EMPTIES_REQUIRED: 'Required: {number}',
      NO_EMPTIES_AVAILABLE: 'There are no empties available for this simulation.',
      PAYMENT_METHOD: 'Payment method',
      PAYMENT_SELECTED: 'Selected: {name}',
      PAYMENT_SELECTED_NONE: 'None',
      PAYMENT_BOLETO_SELECT_LABEL: 'Choose due day',
      PAYMENT_BOLETO_SELECT_PLACEHOLDER: 'Select',
      PAYMENT_METHOD_PLATFORMS_UNAVAILABLE: 'Unavailable for this account.',
      PAYMENT_METHOD_PLATFORMS: 'Available for this account on {platforms}.',
      DELIVERY_TITLE: 'Delivery date',
      DELIVERY_ADDRESS: 'Delivery address: {address}',
      DELIVERY_EXPRESS: 'Express',
      DELIVERY_FLEXIBLE: 'Flexible',
      DELIVERY_REGULAR: 'Regular',
      DELIVERY_FREE: 'Add {price} to your order to get free delivery.',
      DELIVERY_MET_FREE: 'Your order met the free delivery minimum.',
      FREE_GOODS_TITLE: 'Free goods',
      FREE_GOODS_COUNT_TOOLTIP: '{number} product(s) available as free goods.',
      FREE_GOODS_AVAILABLE: 'Available: {required} required, {optional} optional',
      FREE_GOODS_NONE_AVAILABLE: 'Available: none',
      FREE_GOODS_REQUIRED: 'Required free goods',
      FREE_GOODS_OPTIONAL: 'Optional free goods',
    },
    DETAILS: {
      TITLE: 'Account Details',
      ACCOUNT_ID: 'Account ID',
      ABOUT: 'About',
      CREDIT: 'Credit',
      SALES_REP: 'Sales Rep',
      LIQUOR_LICENSE: 'Liquor License',
      ACCOUNT_OWNER: 'Account Owner',
      NAME: 'Name',
      CUSTOMER_ACCOUNT_ID: 'Customer Account ID',
      VENDOR_ACCOUNT_ID: 'Vendor Account ID',
      VENDOR_NAME: 'Vendor Name',
      VENDOR_ID: 'Vendor ID',
      CLASSIFICATION_TYPE: 'Classification Type',
      PAYMENT_METHOD: 'Payment Method',
      ERP_SALES_CENTER: 'ERP Sales Center',
      ZIPCODE: 'Zip code',
      CHANNEL: 'Channel',
      SEGMENT: 'Segment',
      SUB_SEGMENT: 'Sub Segment',
      LAST_MODIFIED_DATE: 'Last Modified Date',
      EMAIL: 'Email',
      PHONE: 'Phone',
      AVAILABLE: 'Available',
      BALANCE: 'Balance',
      CONSUMPTION: 'Consumption',
      OVERDUE: 'Overdue',
      TOTAL: 'Total',
      PAYMENT_TERMS: 'Payment Terms',
      UPDATED_AT: 'Updated At',
      STATUS: 'Status',
      NUMBER: 'Number',
      TYPE: 'Type',
      EXPIRATION_DATE: 'Expiration Date',
      DESCRIPTION: 'Description',
    },
    INVOICES: {
      PAGE_TITLE: 'Invoices',
      NO_INVOICES_FOUND: 'No invoices found.',
      INVOICES_RESULTS_FOUND: '{quantity} invoices',
      INVOICE_RESULT_FOUND: '{quantity} invoice',
      SEARCH: 'Search an invoice',
      INVOICE_NUMBER_FILTER: 'Invoice number',
      ORDER_NUMBER_FILTER: 'Order number',
      CUSTOMER_INVOICE_NUMBER: 'Customer Invoice Number',
      STATUS: 'Status',
      DATE: 'Date',
      ORDER_DATE: 'Order Date',
      CREATED_DATE: 'Created Date',
      PAYMENT_TYPE: 'Payment Type',
      SUBTOTAL: 'Subtotal',
      TOTAL: 'Total',
    },
    CUSTOMERS: {
      TITLE: 'Customers',
      NAME: 'Name',
      EMAIL: 'E-mail',
      PHONE: 'Phone',
      NO_CUSTOMERS_FOUND: 'No customers found',
    },
    INVOICE_DETAILS: {
      INVOICES: 'Invoices',
      TITLE: 'Invoice Details',
      PURCHASES: 'Purchases',
      STATUS: 'Status',
      CUSTOMER_INVOICE_NUMBER: 'Cust. Invoice No',
      PO_NUMBER: 'Po Number',
      PAYMENT_METHOD: 'Payment Method',
      DELETED: 'Deleted',
      CHANNEL: 'Channel',
      PLACED_ON: 'Placed On',
      CREATED_DATE: 'Created Date',
      SUMMARY_TITLE: 'Invoice',
      DETAILS: 'Details',
      MODAL_TITLE: 'Price Details',
      INVOICE_NUMBER: 'Invoice #{customerInvoiceNumber}',
      MODAL_PRICE: 'Price',
      MODAL_QUANTITY: 'Quantity',
      MODAL_DISCOUNT: 'Discount',
      MODAL_TAX: 'Tax',
      MODAL_SUBTOTAL: 'Subtotal',
      MODAL_TOTAL: 'Total',
    },
    REWARD_CHALLENGE_DETAILS: {
      TITLE: 'Rewards',
      CHALLENGE_DETAILS: 'Challenge details',
      SHOW_JSON: 'Show detailed JSON',
      PICTURE_TITLE: 'Picture',
      PICTURE_NOT_FOUND: 'Picture not found',
      PICTURE_NOT_FOUND_DESCRIPTION:
        'This challenge was not accepted yet, so no picture has been sent.',
      SKU_TITLE: 'Products',
      SUBTITLE_PURCHASE:
        'To complete this challenge, an order must be made following exactly the details below.',
      SUBTITLE_PURCHASE_MULTIPLE: `To complete this challenge, the order must follow the quantities below. The SKUs bought count as progress when they’re part of a single order or multiple ones, as long as they match.`,
      SUBTITLE_PURCHASE_MULTIPLE_VOLUME_FIXED: `To complete this challenge, the products below can be bought in any quantity, but the total required must be met. The SKUs bought count as progress when they’re part of a single order or multiple ones, as long as they match.`,
    },
    REWARDS: {
      TITLE: 'Rewards',
      POINTS_NUMBER: '{value} pts',
      TRANSACTION_HISTORY: 'Transaction history',
      CHALLENGE_DETAILS: 'Challenge details',
      TRANSACTION_DETAILS: 'Transaction details',
      CHALLENGES: 'Challenges',
      TRANSACTION_ID: 'Transaction ID',
      POINTS: 'Points',
      DATE: 'Date',
      TYPE: 'Type',
      CHALLENGE_NAME: 'Challenge name',
      CHALLENGE_ID: 'Challenge ID',
      CHALLENGE_DESCRIPTION: 'Description',
      STATUS: 'Status',
      SUBMISSION_DATE: 'Submission date',
      START_DATE: 'Start date',
      END_DATE: 'End date',
      LAST_MODIFIED: 'Last modified',
      GO_BACK: 'go back',
      ACCOUNT_NOT_REGISTERED: 'Account not registered',
      NO_REWARDS_FOUND:
        'Try using a different account name or ID registered in the Club B program.',
      NO_CHALLENGES_FOUND: 'Challenge not found',
      NO_CHALLENGES_FOUND_DESCRIPTION: 'Try again using a different challenge name or ID.',
      CHALLENGES_FOUND: '{number} challenges found',
      CHALLENGES_FILTER_PLACEHOLDER: 'Search by challenge name or ID',
      CHALLENGE_AVAILABLE_FILTER: 'Available',
      CHALLENGE_ACCEPTED_FILTER: 'Accepted',
      CHALLENGE_PENDING_FILTER: 'Pending',
      CHALLENGE_FAILED_FILTER: 'Failed',
      CHALLENGE_COMPLETED_FILTER: 'Completed',
      CHALLENGE_EXPIRED_FILTER: 'Expired',
    },
    ORDER_DETAILS: {
      ORDER_COMMENT: 'Order Comments',
      ORDER_SCORE: 'Order Score',
      ORDERS: 'Orders',
      PURCHASES: 'Purchases',
      TITLE: 'Order details',
      SHOW_HISTORY: 'Show status updates',
      MODAL_TITLE: 'Status updates',
      SUMMARY_TITLE: 'Order summary',
      SUMMARY_STATUS: 'Status',
      SUMMARY_ERP_ORDER_NUMBER: 'ERP order number',
      SUMMARY_ORDER_NUMBER: 'Order number',
      SUMMARY_PAYMENT_METHOD: 'Payment method',
      SUMMARY_DELIVERY_DATE: 'Delivery date',
      SUMMARY_CHANNEL: 'Channel',
      SUMMARY_PLACED_ON: 'Placed on',
      SUMMARY_LOCATION_TITLE: 'Location',
      SUMMARY_LOCATION_STREET_ADDRESS: 'Street address',
      SUMMARY_LOCATION_CITY: 'City',
      SUMMARY_LOCATION_COUNTRY: 'Country',
      SUMMARY_LOCATION_NEIGHBORHOOD: 'Neighborhood',
      SUMMARY_LOCATION_STATE: 'State',
      SUMMARY_LOCATION_EXTRA_ADDRESS_INFO: 'Extra address info',
      SUMMARY_LOCATION_NUMBER: 'Number',
      SUMMARY_LOCATION_ZIPCODE: 'Zipcode',
      SUMMARY_LOCATION_COORDINATES: 'Coordinates',
      SUMMARY_INVOICING_TITLE: 'Invoicing',
      SUMMARY_INVOICING_LEGAL_NAME: 'Legal name',
      SUMMARY_INVOICING_DISPLAY_NAME: 'Display name',
      SUMMARY_INVOICING_EMAIL: 'Email',
      SUMMARY_INVOICING_PHONE: 'Phone',
      SUBTOTAL: 'Subtotal',
      GRAND_TOTAL: 'Grand total',
      DISCOUNT: 'Discount',
      TAX: 'Tax',
      TABLE_ITEM: 'Item',
      TABLE_QUANTITY: 'Quantity',
      TABLE_PRICE: 'Price',
      TABLE_TOTAL: 'Total',
    },
    TOOLS: {
      ACCOUNT_TITLE: 'Account',
      ACCOUNT_DETAILS_TITLE: 'Account details',
      ALGO_SELLING_TITLE: 'Algo Selling',
      PRODUCTS_TITLE: 'Products',
      ORDERS_TITLE: 'Orders',
      INVOICES_TITLE: 'Invoices',
      CUSTOMERS_TITLE: 'Customers',
      DELIVERY_TITLE: 'Delivery',
      REWARDS_TITLE: 'Rewards',
      TRUCK_SIMULATION_TITLE: 'Truck simulation',
      ACCOUNT_DETAILS_TEXT: 'General information, sales rep, liquor license & account owner.',
      PRODUCTS_TEXT:
        'Detailed information about the relationship between the catalog and this account.',
      ALGO_SELLING_TEXT: 'Products currently being recommended for this account & other use cases.',
      ORDERS_TEXT: 'All the orders made by this account.',
      INVOICES_TEXT: 'All the orders generated for this account.',
      CUSTOMERS_TEXT: 'View all the customers that have access to this account.',
      DELIVERY_TEXT: 'General information and delivery windows of this account.',
      REWARDS_TEXT: 'General information, transactions and challenges of this account.',
      TRUCK_SIMULATION_TEXT: 'Simulate the customer checkout experience.',
    },
    PRODUCTS: {
      PAGE_TITLE: 'Products',
      EMPTY_STATE_TITLE: 'No products to show',
      EMPTY_STATE_DESCRIPTION: 'This account does not have any products.',
      NO_PRODUCTS_FOUND: 'No products found',
      SEARCH_PLACEHOLDER: 'Search a SKU',
      TABLE_HEADER_ITEM: 'Item',
      TABLE_HEADER_TYPE: 'Type',
      TABLE_HEADER_MICROSERVICE: 'MicroServID',
      TABLE_HEADER_BASE_PRICE: 'Base Price',
      TABLE_HEADER_CATEGORIES: 'Categories',
      TABLE_HEADER_SITUATION: 'Situation',
      TABLE_HEADER_PROMOTIONAL_PRICE: 'Promot. Price',
      TABLE_HEADER_EXPIRATION_DATE: 'Expiration Date',
      TABLE_NO_PRODUCTS: 'No products found',
      DISCOUNT_MODAL_TITLE: 'Discount',
      STEPPED_DISCOUNT_MODAL_TITLE: 'Stepped Discount',
      COMBO_MODAL_TITLE: 'Combo',
      MIX_MATCH_MODAL_TITLE: 'Mix&Match',
      FREE_GOOD_MODAL_TITLE: 'Free Good',
      STEPPED_FREE_GOOD_MODAL_TITLE: 'Stepped Free Good',
      INTERACTIVE_COMBO_MODAL_TITLE: 'Interactive Combo',
      DISCOUNT_VALUE: '{value}% OFF',
      NO_PRICE: 'No Price',
      ALL_GOOD: 'All Good',
      TIMELINE_DETAILS: 'Product details',
      TIMELINE_CATEGORIES: 'Categories',
      TIMELINE_CATEGORY_BUTTON_LABEL: 'View',
      TIMELINE_PRODUCT_ASSORTMENT: 'Product assortment',
      TIMELINE_PRICING: 'Pricing',
      TIMELINE_STOCK: 'Stock',
      TIMELINE_STOCK_QT: '{quantity} available(s)',
      TIMELINE_MAXIMUM_ORDER_QT: 'Maximum order quantity',
      TIMELINE_ITEM_TYPE: 'Package type: {type}',
      TIMELINE_MULTIPLIER:
        'Multiplier: {number} (It may be purchased following multiples of {number})',
      NOT_FOUND: 'Not found',
      ITEM_PLATFORM_ID_LABEL: 'Item Platform ID',
    },
    SELECTION: {
      NO_ACCOUNT_ID_ERROR:
        'This account does not have an accountId, so this account selection cannot proceed',
      ACCOUNT_ID_TOOLTIP: 'Account ID',
      ACCOUNT_NAME_TOOLTIP: 'Account Name',
      VENDOR_ID_TOOLTIP: 'Vendor ID',
      ACCOUNT_MODAL_TITLE: 'What is Account ID?',
      ACCOUNT_MODAL_TEXT:
        'The Account ID is a hash created from the connection of the Customer Account ID with the Vendor ID. It’s used to locate an Account directly connected to a specific vendor.',
      CUSTOMER_MODAL_TITLE: `I don’t have a Customer Account ID`,
      CUSTOMER_MODAL_TEXT:
        'In this case you can also find an Account by a Customer information (phone, e-mail, name) by ',
      CUSTOMER_FIRST_STEP_PLACEHOLDER: 'Find customer by {name}',
      CUSTOMER_SECOND_STEP_PLACEHOLDER: 'Search an account by name or ID...',
      CUSTOMER_AZURE_ID: 'Azure ID',
      CUSTOMER_ASSOCIATED_ACCOUNTS: '{quantity} associated accounts',
      SECOND_STEP_SUBTITLE: 'Select a Vendor',
      SECOND_STEP_PLACEHOLDER: 'Search a Vendor...',
      FIRST_STEP_SUBTITLE: 'Account',
      FIRST_STEP_CUSTOMER_SUBTITLE: 'Customers',
      FIRST_STEP_TITLE: 'Step 1 of 2',
      SECOND_STEP_TITLE: 'Step 2 of 2',
      SECOND_STEP_CUSTOMER_SUBTITLE: 'Customer',
      DROPDOWN_LABEL: 'Find account by',
      CUSTOMER_MODAL_BUTTON_LABEL: 'clicking here.',
      FIND_ACCOUNT_BUTTON: 'Find Account',
      MODAL_BUTTON_LABEL: 'Got it',
      ACCOUNT_ID_ERROR: 'Insert an existing Account ID',
      ACCOUNT_ID_INPUT_LABEL: 'Account ID',
      ACCOUNT_ID_INPUT_PLACEHOLDER: 'Insert Account ID here...',
      ACCOUNT_ID_MODAL_BUTTON_LABEL: 'What is an Account ID?',
      CUSTOMER_ACCOUNT_ID_ERROR: 'Insert an existing Customer Account ID',
      CUSTOMER_ACCOUNT_ID_INPUT_LABEL: 'Customer Account ID',
      CUSTOMER_ACCOUNT_ID_INPUT_PLACEHOLDER: 'Insert Customer Account ID here...',
      CUSTOMER_ACCOUNT_ID_MODAL_BUTTON_LABEL: ' I don’t have a Customer Account ID',
      RESULTS_FOUND: '{quantity} results found',
      RESULT_FOUND: '{quantity} result found',
      SECOND_STEP_ERROR_MESSAGE:
        "It seems you don't have access to this account's vendors. Do you want to ",
      SECOND_STEP_ERROR_MESSAGE_LINK: 'go back?',
      SECOND_STEP_GO_BACK: 'go back',
      CUSTOMER_RESULTS_FOUND: '{quantity} results found',
      NAME_LABEL: 'Name',
      EMAIL_LABEL: 'E-mail',
      PHONE_NUMBER_LABEL: 'Phone number',
      NOT_REGISTERED: 'Not registered',
      SECOND_STEP_NOT_ALLOWED:
        "It seems there's no account associated with this customer or you don't have access to see it. Do you want to {goBack}?",
    },
    ORDERS: {
      SEARCH: 'Insert an Order Number',
      PAGE_TITLE: 'Orders',
      ORDERS_RESULTS_FOUND: '{quantity} orders',
      ORDER_RESULT_FOUND: '{quantity} order',
      ORDER_NUMBER: 'Order Number',
      STATUS_LABEL: 'Status',
      PLACEMENT_DATE: 'Placement Date',
      DELIVERY_DATE_LABEL: 'Delivery Date',
      SUBTOTAL_LABEL: 'Subtotal',
      TOTAL_LABEL: 'Total',
      NO_ORDERS_FOUND: 'No orders found.',
    },
    DELIVERY_WINDOWS: {
      PAGE_TITLE: 'Delivery',
      GENERAL_INFORMATION: 'General information',
      DELIVERY_WINDOWS: 'Delivery window',
      DELIVERY_CENTER_ID: 'Delivery Center ID',
      DELIVERY_SCHEDULE_ID: 'Delivery Schedule ID',
      DELIVERY_DATE: 'Delivery date',
      TYPE: 'Type',
      EXPIRES: 'Deadline for delivery',
      PRICE: 'Price',
      NO_DELIVERY_WINDOWS_FOUND: 'No Delivery windows found.',
      REGULAR_TOOLTIP: 'Regular deliveries are not charged.',
      FLEXIBLE_TOOLTIP: 'Flexible deliveries have an additional fee.',
      EXPRESS_TOOLTIP: 'Express deliveries are made the same day the order is placed.',
      DELIVERY_PHRASE: 'The delivery date is from {minDate} to {maxDate} business days.',
      DELIVERY_ADDRESS: 'Delivery address: {address}',
    },
  },
  MODAL: {
    TRUCK_ALERTS: {
      TITLE: 'Truck alerts',
      ERRORS_TAB: 'Errors ({number})',
      WARNINGS_TAB: 'Warnings ({number})',
      INFORMATION_TAB: 'Information ({number})',
      NO_ERRORS: 'No errors found.',
      NO_WARNINGS: 'No warnings found.',
      NO_INFORMATION: 'No information to show.',
    },
    BEES_VENDO: {
      TITLE: 'Account Selection',
      PARAGRAPH: 'Insert another Account ID to access its Transaction History.',
      PLACEHOLDER: 'Insert Account ID here...',
    },
    DEALS: {
      DEALS_TYPE: 'Deals type(s)',
      VENDOR_PROMOTION_ID: 'Vendor Promotion ID',
      DISCOUNT_NAME: 'Discount name',
      DESCRIPTION: 'Description',
      BASE_PRICE: 'Base price',
      DISCOUNT: 'Discount',
      FINAL_PRICE: 'Final price',
      AMOUNT_TO_REACH_VALUE: 'Amount to reach: {value}x',
      SD_QUANTITY: 'Quantity',
      SD_DISCOUNT: 'Discount',
      SD_PRICE: 'Price',
      DISCOUNT_RANGE: 'Discount range',
      FREE_GOOD_OPTIONS: 'Free good options',
      SFG_BUY: 'Buy',
      SFG_GET: 'Get',
      SFG_DIVISOR: 'Divisor',
      STEPPED_DISCOUNT: 'Stepped discount',
      FREE_GOOD: 'Free Good',
      STEPPED_FREE_GOOD: 'Stepped free good',
    },
    CATEGORY: {
      TITLE: 'Categories',
      CATEGORIES_SECTION: 'Categories:',
    },
  },
  CUSTOMER_DETAILS: {
    TITLE: 'Customer Details',
    ACCOUNTS_ASSOCIATED: 'Accounts Associated',
    PLACEHOLDER: 'Search an account by name or ID...',
  },
  LOADING: {
    ALMOST_THERE: "We're almost there...",
    LOADING: 'Loading',
    LOADING_ACCOUNTS: 'Loading accounts',
    LOADING_CUSTOMERS: 'Loading customers',
  },
  ACCOUNT_CARD: {
    TITLE: 'Account',
    NO_ACCOUNT: 'No data to display',
    VENDOR_NAME_LABEL: 'Vendor: {vendorName}',
    CUSTOMER_ACCOUNT_ID_LABEL: 'Customer Account ID: {customerAccountId}',
    ACCOUNT_ID_LABEL: 'Account ID: {accountId}',
  },
  STORE_CARD: {
    NO_STORE: 'No data to display',
    STORE_ID: 'ID: {storeId}',
    DEFAULT_VENDOR: 'Default Vendor: {vendorName}',
  },
  BEES_VENDO: {
    ACCOUNT_SELECTION: {
      PAGE_TITLE: 'BEES Vendo',
      TITLE: 'Account Selection',
      SUB_TITLE: 'Insert an Account to  view its BEES Vendo Transactions',
      PLACEHOLDER: 'Insert Account ID here...',
      FIND_BUTTON_LABEL: 'Access Account',
      MODAL_TITLE: 'Change Account ID',
      MODAL_BUTTON_LABEL: 'Change Account ID',
      MODAL_BUTTON_LABEL_BACK: 'Back',
      MODAL_BUTTON_LABEL_SEARCH: 'Search',
    },
    TRANSACTION: {
      TITLE: 'Transaction History',
      EMPTY_TABLE: 'No transactions were found for the Account {accountId}',
      INVALID_ACCOUNT_ID: 'The account id {accountId} does not exist',
      ACCOUNT_ID: 'Account ID',
      BALANCE: 'Balance',
      CREDIT_BOUGHT: 'BEES Credit bought',
      RECHARGE: 'Recharge sold',
      PHONE_NUMBER: 'Phone Number',
      CARRIER: 'Carrier',
      ITEM_COUNT: '({count} transactions)',
      TRANSACTION_TYPE: 'Transaction Type',
      TRANSACTION_ID: 'Transaction Id',
      ORDER_ID: 'Order Id',
      AMOUNT: 'Amount',
      DATE: 'Date',
    },
  },
  BREADCRUMB: {
    ACCOUNTS: 'Accounts',
    ACCOUNT_DETAILS: 'Account details',
    ACCOUNTS_SELECTION: 'Accounts',
    APIARY_TOOLS: 'Retailer App Monitoring Tools',
    ALGO_SELLING: 'Algo-selling',
    CUSTOMERS: 'Customers',
    CATEGORIES: 'Categories',
    INVOICES: 'Invoices',
    INVOICE_DETAILS: 'Invoice details',
    ORDERS: 'Orders',
    ORDER_DETAILS: 'Order details',
    REWARDS: 'Rewards',
    PRODUCTS: 'Products',
    VENDORS: 'Vendors',
    VENDOR_DETAILS: 'Vendor details',
    BEES_VENDO: 'BEES Vendo',
    DELIVERY: 'Delivery',
    TRUCK: 'Truck',
    TRUCK_SIMULATION: 'Truck simulation',
    STORES: 'Stores',
    ITEMS: 'Items',
  },
  CONSTS: {
    GO_BACK: 'Go back',
    RESULTS_FOUND: '{quantity} results found',
    RESULT_FOUND: '{quantity} result found',
    NOT_AVAILABLE: 'Not available',
    DEALS: 'Deals',
    ITEM: 'Item',
    COMBO: 'Combo',
    VARIANT: 'Variant',
    NO_RESULTS_FOUND: 'No results found for this search.',
    BEE_NOT_FOUND: 'There’s nothing here.',
    OR: 'Or',
    COPY: 'Copy',
    FREE: 'Free',
    COPIED: 'Copied!',
    ENABLED: 'Enabled',
    SEARCH: 'Search',
    HOME_TOOLS: 'Home',
    DISABLED: 'Disabled',
    ACCOUNT_LABEL: 'Account',
    ACCOUNT_ID_LABEL: 'Account ID',
    CUSTOMER_ACCOUNT_ID_LABEL: 'Customer Account ID',
    DELIVERY_DATE_LABEL: 'Delivery Date',
    STATUS_LABEL: 'Status',
    YES: 'Yes',
    GOVERNMENT_ID_LABEL: 'Government ID',
    MICROSERVICE_ID: 'MicroservID',
  },
  COUNTRY_SELECTOR: {
    CHANGE_COUNTRY: 'Change country',
    CHOOSE_COUNTRY: 'Choose country',
    NO_COUNTRY_DISCLAIMER:
      'Your account does not yet have access to any countries. Please contact your administrator.',
    STEP_COUNTRY: 'Select your country',
    STEP_ENVIRONMENT: 'Select your environment',
    STEP_TOOLS: 'Use Retailer App Monitoring tools',
    LANGUAGE: 'Language:',
  },
  CUSTOMER_CARD: {
    NO_CUSTOMER: 'No data to display',
  },
  COUNTRY_SELECTION: {
    BEES_APIARY_TOOLS_CAPTION: 'Select your country to access Retailer App Monitoring Tools',
    BEES_APIARY_TOOLS_LABEL: 'Retailer App Monitoring',
    NO_ENVIRONMENT_ALERT: 'Your account does not have access to any environments for this country.',
  },
  GENERAL: {
    NO_APIARY_ACCESS:
      'Your account does not yet have access to this tool. If you believe this is a mistake, please contact your administrator.',
  },
  PAGINATION: {
    ALL: 'All',
    FIRST: 'First page',
    LAST: 'Last page',
    NEXT: 'Next page',
    PREVIOUS: 'Previous page',
    ROWS_PER_PAGE: 'Rows per page:',
    PAGE: 'Page {page}',
  },
  HOME_TOOLS: {
    TITLE: 'What information would you like to see?',
    ACCOUNT_TITLE: 'Account',
    ACCOUNT_TEXT: 'Detailed information about BEES Customer accounts.',
    CUSTOMER_TITLE: 'Customer',
    CUSTOMER_TEXT: 'Customer details and associated accounts.',
    VENDOR_TITLE: 'Vendor',
    VENDOR_TEXT: 'All details and products offered by a vendor.',
    STORE_TITLE: 'Store',
    STORE_TEXT: 'Store vendors, categories, items and content.',
    BEES_VENDO_TITLE: 'BEES Vendo',
    BEES_VENDO_TEXT: 'Transaction history and details about BEES Vendo accounts.',
  },
  VENDOR: {
    SELECTION: {
      PAGE_TITLE: 'Vendors',
      VENDOR_ID: 'ID: {vendorId}',
      GOVERNMENT_ID: 'GovID: {governmentId}',
      SERVICE_MODEL: 'Service Model: {serviceModel}',
      PLACEHOLDER: 'Search a vendor by Name or Vendor ID',
      PLURAL_RESULT: '{number} vendors',
      SINGULAR_RESULT: '{number} vendor',
    },
    CATEGORIES: {
      PAGE_TITLE: 'Vendor',
      LIST_TITLE: 'Categories',
      SUBCATEGORIES_ITEMS_TITLE: 'Items Assigned',
      ITEMS_COUNT: '({number} items)',
    },
    CATEGORY_DETAILS: {
      PAGE_TITLE: 'Vendor',
      NO_CATEGORY_FOUND: 'No category found',
      CARD_TITLE: 'Category',
      MICROSERVICE_ID_LABEL: 'Microservice ID',
      CATEGORY_ALERT_MESSAGE:
        'This category contains subcategories and items at the same time, but it can only display the subcategories in the BEES App.',
      ITEMS_ALERT_MESSAGE:
        'These items are not being displayed in the BEES App through this category.',
    },
    DETAILS: {
      PAGE_TITLE: 'Vendor',
      TITLE: 'Vendor details',
      TAB_FEATURES: 'Features',
      TAB_INVENTORY: 'Inventory',
      TAB_ENFORCEMENT: 'Enforcement',
      TAB_ORDERS: 'Orders',
      TAB_FULFILLMENT: 'Fulfillment',
      TAB_PRICING_ENGINE: 'Pricing engine',
      TAB_ACCOUNT: 'Account',
      TAB_ITEMS: 'Items',
      TAB_CHECKOUT: 'Checkout',
      TAB_USERS: 'Users',
      TAB_RECOMMENDATION: 'Recommendation',
    },
    HOME_PAGE: {
      PAGE_TITLE: 'Vendor',
      CATEGORIES_TITLE: 'Categories',
      CATEGORIES_TEXT: 'All the product categories of this vendor.',
      DETAILS_TITLE: 'Vendor details',
      DETAILS_TEXT: 'General information such as orders, items, fulfillment and others.',
    },
  },
  VENDOR_CARD: {
    NO_VENDOR: 'No data to display',
    VENDOR_STORE_ID: 'Store ID: {storeId}',
    VENDOR_ID: 'Vendor ID: {vendorId}',
    VENDOR_SERVICE_MODEL: 'Service Model: {serviceModel}',
  },
  REWARD_CHALLENGE_CARD: {
    LAST_EDIT: 'Last edit: {date}',
    ACCEPTED: 'Accepted: {date}',
    PROGRESS_CASES: '{quantityPurchased}/{quantity} cases',
  },
  REWARD_HEADER: {
    BALANCE: 'Balance',
    REGISTERED_DATE: 'Registered: {date}',
    POINTS_NUMBER: '{value} pts',
  },
  PRODUCT_DISPLAY: {
    PACKAGE: 'Package: ',
  },
  CATEGORY_DETAILS: {
    DETAILS_TITLE: 'Category Details',
    CARD_TITLE: 'Categories',
    CATEGORY: 'Category',
    MICROSERVICE_ID: 'Microservice ID',
    STATUS: 'Status',
    ITEM: 'Item',
    ITEM_PLATFORM_ID: 'Item Platform ID',
    SUBCATEGORIES: 'Subcategories',
    ITEMS_ASSIGNED: 'Items Assigned',
    ITEM_FILTER_PLACEHOLDER: 'Search an Item Name or Vendor Item ID',
    ITEM_TABLE_HEADER: 'Item',
    VENDOR_TABLE_HEADER: 'Vendor',
    STATUS_TABLE_HEADER: 'Status',
    ITEM_PLATFORM_ID_TABLE_HEADER: 'Item Platform ID',
    VARIANT_TABLE_HEADER: 'Variant',
    HIDDEN_TABLE_HEADER: 'Hidden',
    NARCOTIC_TABLE_HEADER: 'Narcotic',
    ALCOHOLIC_TABLE_HEADER: 'Alcoholic',
    ITEMS_TABLE_ERROR: 'These items are not being displayed in the BEES App through this category.',
    CATEGORY_ERROR:
      'This category contains subcategories and items at the same time, but it can only display the subcategories in the BEES App.',
  },
};

export default en;
