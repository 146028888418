import { Grid } from '@hexa-ui/components';
import { Currency, Home, Store, Store2, TreeDiagram, Users } from '@hexa-ui/icons';
import { useAuthenticationService, useSidebar } from 'admin-portal-shared-services';
import { countryList } from 'consts/countryList';
import { routes } from 'consts/routes';
import { ROUTE_NAMES, useApiaryNavigation } from 'hooks/useApiaryNavigation';
import { useCountryFeatures } from 'hooks/useCountryFeatures';
import { useGlobalState } from 'hooks/useGlobalState';
import { formatMessage } from 'i18n/formatters';
import { ICountry } from 'interfaces/ICountry';
import { FC, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { setCountry } from 'redux/actions';
import * as singleSpa from 'single-spa';
import { AppContainer } from './styles';

interface IConnectedWrapperProps {
  children: ReactNode;
}

export const ConnectedWrapper: FC<IConnectedWrapperProps> = ({ children }) => {
  const {
    clearState,
    system: { selectedCountry },
    cardBox: { account },
  } = useGlobalState();
  const { getUrl, navigate } = useApiaryNavigation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isMarketplace, isVendo } = useCountryFeatures();
  const authenticationService = useAuthenticationService();
  const supportedCountries = authenticationService.getSupportedCountries();

  const params = useMemo(
    () => ({
      accountId: account?.accountId,
      vendorId: account?.vendor?.vendorId,
    }),
    [account]
  );

  const countryFromUrl = ((): ICountry | null | undefined => {
    const countryCode = location.pathname.slice(8, 10);
    const availableCountryCode = supportedCountries?.find((code) => code === countryCode);
    const country = countryList?.find(
      ({ code }) => availableCountryCode && code === availableCountryCode
    );
    if (country && country?.code !== selectedCountry?.code) {
      clearState();
      dispatch(setCountry(country));
    }
    return country;
  })();

  useEffect(() => {
    if (!countryFromUrl && location.pathname !== routes.COUNTRY_SELECTION) {
      navigate({ path: ROUTE_NAMES.ERROR_403 });
    }
    // If the dependency array below receives the countryFromUrl and navigate variables, a infinite loop begins
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkUserScopes = useCallback(async () => {
    const status = singleSpa.getAppStatus('@admin-portal/bees-apiary-beta-mfe');
    if (status === 'NOT_MOUNTED') {
      if (
        selectedCountry?.code &&
        !supportedCountries.find((code) => code === selectedCountry?.code)
      ) {
        dispatch(setCountry());
      }
    }
  }, [dispatch, selectedCountry?.code, supportedCountries]);

  useEffect(() => {
    checkUserScopes();
  }, [checkUserScopes, selectedCountry?.code]);

  const sidebarLinks = useMemo(
    () => ({
      items: [
        {
          title: formatMessage({ id: 'CONSTS.HOME_TOOLS' }),
          path: getUrl({ path: ROUTE_NAMES.HOME_TOOLS, params }),
          icon: () => <Home />,
          enabled: true,
          id: 'tools-menu-home',
        },
        {
          title: formatMessage({ id: 'HOME_TOOLS.ACCOUNT_TITLE' }),
          path: getUrl({
            path: account ? ROUTE_NAMES.ACCOUNT_TOOLS : ROUTE_NAMES.ACCOUNT_SELECTION_STEP_ONE,
            params,
          }),
          icon: () => <Store />,
          enabled: true,
          id: 'tools-menu-account',
        },
        {
          title: formatMessage({ id: 'HOME_TOOLS.CUSTOMER_TITLE' }),
          path: getUrl({
            path: ROUTE_NAMES.CUSTOMER_SELECTION_STEP_ONE,
            params,
          }),

          icon: () => <Users />,
          enabled: true,
          id: 'tools-menu-customers',
        },
        {
          title: formatMessage({ id: 'HOME_TOOLS.VENDOR_TITLE' }),
          path: getUrl({ path: ROUTE_NAMES.VENDORS_LIST, params }),
          icon: () => <TreeDiagram />,
          enabled: true,
          id: 'tools-menu-vendors',
        },
        {
          title: formatMessage({ id: 'HOME_TOOLS.STORE_TITLE' }),
          path: getUrl({ path: ROUTE_NAMES.STORES_LIST, params }),
          icon: () => <Store2 />,
          enabled: isMarketplace,
          id: 'tools-menu-stores',
        },
        {
          title: formatMessage({ id: 'HOME_TOOLS.BEES_VENDO_TITLE' }),
          path: getUrl({ path: ROUTE_NAMES.BEES_VENDO_SELECTION, params }),
          icon: () => <Currency />,
          enabled: isVendo,
          id: 'tools-menu-vendo',
        },
      ].filter(({ enabled }) => enabled),
      utils: [],
    }),
    [getUrl, params, account, isMarketplace, isVendo]
  );

  useSidebar(sidebarLinks);

  return (
    <Grid.Container sidebar type="fluid">
      <AppContainer>{children}</AppContainer>
    </Grid.Container>
  );
};
